<template>
    <h2>
        {{ $t("Distributor.AuthorizedLineCardHeading") }}
        <span class="text-muted text-base font-normal">{{ props.regionName }}</span>
    </h2>

    <p>
        {{ $t("Distributor.AuthorizedLineCardParagraph", [distributor?.Name]) }}
    </p>

    <div class="sticky top-20 hidden md:flex bg-white">
        <a
            v-for="(group, key) in groupings"
            :key="key"
            :href="`#${key}`"
            class="block text-center w-12 h-12 leading-12 text-sm font-bold mb-1 hover:bg-accent hover:text-white transition-bg"
            >{{ key }}</a
        >
    </div>

    <div v-if="groupings" class="divide-y divide-gray-100">
        <div v-for="(group, key) in groupings" :key="key" class="mb-4">
            <h3 :id="`${key}`" class="my-4 md:scroll-pt-[130px] md:scroll-mt-[130px]">
                {{ key }} ({{ group.length }})
            </h3>
            <ul class="list-style-none columns-2xs">
                <li v-for="mfr in group" :key="mfr.VirtualPath" class="mb-1">
                    <NuxtLink
                        :to="localePath('/manufacturers/' + mfr.VirtualPath)"
                        :title="mfr.DisplayName"
                        class="block pb-1"
                        external>
                        {{ mfr.DisplayName }}
                    </NuxtLink>
                </li>
            </ul>
        </div>
    </div>
</template>
<script setup lang="ts">
import type { RegionManufacturers } from "~/utils/types/RegionManufacturers";

const props = defineProps({
    regionName: { type: String, required: true },
});

const localePath = useLangPath();
const distributor = useStateDistributor();

interface Grouping {
    VirtualPath: string;
    DisplayName: string;
}

const groupings = computed(() => {
    const regionManufacturers = distributor.value?.RegionManufacturers;
    const groups: Record<string, Grouping[]> = {};

    if (regionManufacturers) {
        regionManufacturers.forEach((regionManufacturer: RegionManufacturers) => {
            const name = regionManufacturer.DisplayName;
            const firstChar: string = name.charAt(0).toUpperCase();
            const key = /\d/.test(firstChar) ? "#" : firstChar;

            if (!groups[key]) {
                groups[key] = [];
            }

            groups[key].push({
                VirtualPath: regionManufacturer.VirtualPath,
                DisplayName: regionManufacturer.DisplayName,
            });
        });

        Object.keys(groups).forEach((key) => {
            groups[key].sort((a, b) => a.DisplayName.localeCompare(b.DisplayName));
        });

        return (Object.keys(groups) as Array<keyof typeof groups>)
            .sort()
            .reduce((obj: Record<string, Grouping[]>, key: string) => {
                obj[key] = groups[key];

                return obj;
            }, {});
    }

    return null;
});
</script>
