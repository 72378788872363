<template>
    <div v-if="distributor" class="pt-8 flex gap-4 md:gap-8 justify-between max-md:flex-col">
        <a
            :href="distributor.WebsiteUrl"
            class="shrink-0"
            rel="noopener nofollow"
            target="_blank"
            :data-dist="props.distLinkAttribute"
            data-ga="d">
            <EnhancedImage
                :src="cdnUrl + distributor.LogoUrl"
                :alt="distributor.Name"
                height="96"
                width="140"
                css-class="max-h-24" />
        </a>
        <div class="max-w-5xl">
            <p v-if="distributor.Description">{{ distributor.Description }}</p>
            <p>
                <a
                    :href="distributor.WebsiteUrl"
                    class="shrink-0"
                    rel="noopener nofollow"
                    target="_blank"
                    :data-dist="props.distLinkAttribute"
                    data-ga="d">
                    {{ t("Distributor.VisitSiteText", [distributor.Name]) }}
                </a>
            </p>
        </div>
    </div>
</template>
<script setup lang="ts">
const { t } = useI18n();
const props = defineProps({
    distLinkAttribute: { type: String, required: true },
});
const config = useRuntimeConfig();
const cdnUrl = config.public.cdnUrl;
const distributor = useStateDistributor();
</script>
