<template>
    <section class="flex flex-col text-base">
        <PageHeader v-if="distributor?.Name" wide>
            <h1>
                {{ distributor?.Name }}
                <span class="font-normal text-gray-500 text-xl ml-4" v-html="distributorHeadingLabel"></span>
            </h1>
        </PageHeader>
        <div class="container-left flex flex-col gap-4">
            <DistributorDetail v-if="distributor" :dist-link-attribute="distLinkAttribute" />
            <DistributorManufacturerList v-if="distributor" :region-name="distributor?.RegionName" />
        </div>
    </section>
</template>
<script setup lang="ts">
const route = useRoute();
const { t } = useI18n();

const distributor = useStateDistributor();
const { data } = await useFetchLocaleApi<DistributorDetails>(`api/distributors/${route.params.dist}`);
distributor.value = data.value;
if (!data.value) {
    throw createError({
        statusCode: 404,
    });
}

const distLinkAttribute = computed(() =>
    distributor?.value ? `${distributor?.value?.Name} [${distributor?.value?.DistributorId}]` : ""
);

const distributorHeadingLabel = computed(() => distributor.value?.HeadingLabel || "");

useSeoMeta({
    title: t("Distributor.DistributorTitle", [distributor.value?.Name]),
    ogTitle: t("Distributor.DistributorTitle", [distributor.value?.Name]),
    description: t("Distributor.MetaDescriptionFormat", [distributor.value?.Name]),
    ogDescription: t("Distributor.MetaDescriptionFormat", [distributor.value?.Name]),
});
</script>
